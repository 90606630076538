import * as React from "react";
import { graphql } from "gatsby";
import "@fontsource/red-hat-display";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import Subscribe from "../components/misc/check_list";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { Grid } from "@mui/material";
import How from "../components/connect/how";
import Way from "../components/connect/the_way";
import Gallery from "../components/connect/gallery";
import Sticky from "../components/connect/sticky";
import Accordion from "../components/connect/accordion/accordion";
import Tabs from "../components/connect/tabs";
import Article from "../components/connect/article";
import "../static/mainPage.css";
import { StaticImage } from "gatsby-plugin-image";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

const IndexPage = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;
  let header;
  header = (
    <StaticImage
      src="../images/iso_color.png"
      alt="Logo"
      loading="eager"
      formats={["auto", "webp", "avif"]}
    />
  );
  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo
          title="MicroREC Connect | Custom Surgical"
          description="Data Management made easy. Your data is kept safe and secure, access it from any device, any time from anywhere."
        />{" "}
        <Subscribe />
        <>
          <div className="image-container">
            <StaticImage
              src="../components/connect/background.png"
              alt="Main Image"
              loading="eager"
              placeholder="blurred"
              formats={["auto", "webp", "avif"]}
              quality={100}
              style={{
                objectFit: "cover",
                aspectRatio: "2 / 1",
                width: "100%",
                height: "auto",
                height: "80vh",
                backgroundRepeat: "no-repeat",
                backgroundPositionX: "center",
                backgroundSize: "cover",
                width: "100%",
              }}
            />
            <div className="text-overlay">
              <Grid container sx={{ display: "block" }} className="text_over">
                <b>MicroREC Connect</b>
                <Grid item xs={10} sm={10} md={5}>
                  <div style={{ fontSize: "25px", marginTop: "1em" }}>
                    Keep your <b>data safe and secure</b>, access it from{" "}
                    <b>any device, any time from anywhere</b>.
                  </div>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    marginTop: "1em",
                    justifyContent: "flex-start",
                    alignItems: "baseline",
                  }}
                >
                  <button className="shop" style={{ marginRight: "1.5em" }}>
                    <a
                      href="https://connect.customsurgical.co/login"
                      target="_blank"
                      style={{ color: "white", fontSize: "20px" }}
                    >
                      Trial For Free
                    </a>
                  </button>
                </div>
              </Grid>
            </div>
          </div>
        </>
        <Accordion></Accordion>
        <How></How>
        <Way></Way>
        <Sticky />
        <div style={{ marginBottom: "7em" }}></div>
        <Tabs></Tabs>
        <Gallery />
        <Article />
      </Layout>
    </ThemeProvider>
  );
};

export default IndexPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`;

import React from "react";
import Grid from "@mui/material/Grid";
import devices from "./article.png";
import { StaticImage } from "gatsby-plugin-image";

const Stickyy = () => {
  return (
    <>
      <Grid container justifyContent="center">
        <Grid
          container
          item
          xs={12}
          sm={12}
          md={10}
          marginTop={{ xs: 0, sm: 0 }}
          alignItems="center"
          justifyContent="center"
        >
          <Grid item xs={12} sm={6} md={6}>
            <StaticImage
              src="./article.png"
              alt="Main Image"
              loading="lazy"
              placeholder="dominantColor"
              formats={["auto", "webp", "avif"]}
              quality={80}
              style={{ width: "100%" }}
            />
          </Grid>
          <Grid item xs={11} sm={5} md={5}>
            <h4>
              13 REASONS WHY MICROREC CONNECT IS THE BEST PLATFORM FOR YOUR
              OPHTHALMOLOGIC DATA
            </h4>
            <p>
              As{" "}
              <a
                href="/20-Practice-Management-Digitizing-Your-Practice/"
                target="_blank"
                style={{ color: "black", textDecoration: "underline" }}
              >
                healthcare technology advances
              </a>{" "}
              at a rapid pace, there is a growing need for innovative solutions
              that can help medical professionals manage their patients’ records
              more effectively and collaborate with ease.
            </p>
            <p>
              That's where MicroREC Connect comes in - a new data management
              platform that works hand-in-hand with the{" "}
              <a
                href="/microrec-app"
                target="_blank"
                style={{ color: "black", textDecoration: "underline" }}
              >
                MicroREC App
              </a>
              . It not only makes it easy to access patient information from any
              device but also offers a wide range of features designed
              specifically for healthcare providers. Here is a list of the
              reasons why MicroREC Connect is the right platform for you:
            </p>
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                marginBottom: "3em",
                justifyContent: "space-evenly",
                alignItems: "baseline",
              }}
            >
              <a
                href="/34-13-Reasons-why-MicroREC-Connect-is-the-best-platform-for-your-ophthalmologic-data/"
                target="_blank"
                style={{
                  color: "white",
                  backgroundColor: "rgb(142, 79, 255)",
                  padding: "10px 20px",
                  borderRadius: "20px",
                }}
              >
                READ THE ARTICLE
              </a>
              <a
                href="https://wa.me/4915234689005?text=Hello,%20I%20came%20from%20the%20website,%20and%20I%20want%20to%20know%20more%20about"
                target="_blank"
                style={{
                  color: "white",
                  backgroundColor: "rgb(142, 79, 255)",
                  padding: "10px 20px",
                  borderRadius: "20px",
                  marginTop: "10px",
                }}
              >
                TALK TO SALES
              </a>
            </div>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default Stickyy;
